import React from "react"

import Layout from "../components/Layout"

export default function Contact() {
  return (
    <Layout title="Contact Us">
      <div className="contact segments-page default-container">
        <div className="container">
          <div className="form-text">
            <p>
              Please contact us with any questions or comments by filling out
              the form below. We look forward to hearing from you!
            </p>
          </div>
          <div className="content b-shadow">
            <form data-netlify="true" name="pac-trade-contact" method="post">
              <input type="hidden" name="form-name" value="pac-trade-contact" />
              <input
                id="name"
                type="text"
                name="name"
                aria-label="Please enter your name"
                placeholder="Name"
                required
              />
              <input id="email" type="email" name="email" aria-label="Please enter your email address" placeholder="Email" required />
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                aria-label="Please enter your message"
                placeholder="Message"
                required
              ></textarea>
              <button
                name="submit"
                type="submit"
                className="button button-full"
              >
                Send
              </button>
            </form>
          </div>
          <div className="form-text">
            <h2>Contact Information</h2>
            <p>
              Email:{" "}
              <a name="Email Us" href="mailto:info@pac-trade.com">
                info@pac-trade.com
              </a>
              <br />
              Phone: +1 707-234-8221
              <br />
              Address: 8911 Lakewood Dr. Ste 22B, Windsor, CA 95492 USA
              <br />
              D-U-N-S: 09-590-6958
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
